import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeAnalyticsViews = async (to, from) => {
  const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]
  if (!loggedInUserPolicies["Analytics view"]) {
    return "not-found"
  }

  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)

  const promisesArrayToBeResolved = []

  let loadCustomViewsPromise = Promise.resolve()

  if (loggedInUserPolicies["CustomView view"]) {
    loadCustomViewsPromise = store.dispatch("customViews/loadCustomViews")
    promisesArrayToBeResolved.push(loadCustomViewsPromise)
  }

  if (!["analytics-views", "analytics-view"].includes(from.name)) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
    store.commit("shared/setBreadcrumbs", undefined)

    if (loggedInUserPolicies["CustomView view"]) {
      const loadFiltersPromise = store.dispatch("filters/loadFilters")
      promisesArrayToBeResolved.push(loadFiltersPromise)

      loadCustomViewsPromise.then(() => {
        if (loggedInUserPolicies["Group view"]) {
          const groupsPromise = store.dispatch("groups/loadGroups")
          promisesArrayToBeResolved.push(groupsPromise)
        }
      })
    }

    if (loggedInUserPolicies["IssueSearch view"]) {
      const loadIssuesInitialPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
        criteria: {
          searchText               : null,
          issuesFilters            : {},
          searchThroughAllIssueData: false
        },
        excludeFilterPreferences: true
      })
      promisesArrayToBeResolved.push(loadIssuesInitialPromise)

      if (loggedInUserPolicies["Domain view"]) {
        const domainsPromise = store.dispatch("domains/loadDomains")
        promisesArrayToBeResolved.push(domainsPromise)
      }
      if (loggedInUserPolicies["Channel view"]) {
        const loadChannelsPromise = store.dispatch("channels/loadChannels")
        promisesArrayToBeResolved.push(loadChannelsPromise)
      }
      if (loggedInUserPolicies["Language view"]) {
        const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
        promisesArrayToBeResolved.push(loadLanguagesPromise)
      }

      const usersPromise = store.dispatch("users/loadUsers")
      promisesArrayToBeResolved.push(usersPromise)

      if (loggedInUserPolicies["Chart view"]) {
        const loadChartsPromise = store.dispatch("charts/loadCharts")
        promisesArrayToBeResolved.push(loadChartsPromise)
      }

      if (loggedInUserPolicies["IssueField view"]) {
        const loadIssueFieldsPromise = store.dispatch("issueFields/loadIssueFields")
        promisesArrayToBeResolved.push(loadIssueFieldsPromise)
      }

      if (loggedInUserPolicies["FieldV2 view"]) {
        const loadFieldsPromise = store.dispatch("fields/loadFieldsV2")
        promisesArrayToBeResolved.push(loadFieldsPromise)
      }

      if (loggedInUserPolicies["FormTemplate view"]) {
        const loadFormTemplatesPromise = store.dispatch("formTemplates/loadFormTemplates")
        promisesArrayToBeResolved.push(loadFormTemplatesPromise)
      }

      if (loggedInUserPolicies["OptionListItem view"]) {
        const loadOptionListItemsPromise = store.dispatch("optionListItems/loadOptionListItems")
        promisesArrayToBeResolved.push(loadOptionListItemsPromise)
      }

      if (loggedInUserPolicies["IssueFormTemplate view"]) {
        const loadIssueFormTemplatesPromise = store.dispatch("issueFormTemplates/loadIssueFormTemplates")
        promisesArrayToBeResolved.push(loadIssueFormTemplatesPromise)
      }

      if (loggedInUserPolicies["FormTemplateConfiguration view"]) {
        const loadFormTemplateConfigurationsPromise = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations")
        promisesArrayToBeResolved.push(loadFormTemplateConfigurationsPromise)
      }

      const pollPromise = loadIssuesInitialPromise.then(() => {
        const issueSearch       = store.getters["issueSearch/issueSearch"]
        const pollSearchPromise = pollIssueSearch(store, issueSearch.id, true)
        promisesArrayToBeResolved.push(pollSearchPromise)
        return pollSearchPromise
      })
      promisesArrayToBeResolved.push(pollPromise)
    }
  }

  if (to.name === "analytics-view" && loggedInUserPolicies["CustomView view"]) {
    if (!["analytics-views", "analytics-view"].includes(from.name)) {
      await loadCustomViewsPromise
    }
    const customViews = store.getters["customViews/customViews"]
    const customView  = customViews.find(customView => customView.id === +to.params.id)
    if (!customView) {
      return "not-found"
    }
    const customViewIds                 = customViews.map(customView => customView.id)
    const customViewUpdatePolicyPromise = store.dispatch("accessControl/loadLoggedInUserAccess", [{
      policies   : ["CustomView update"],
      resourceIds: customViewIds
    }])
    promisesArrayToBeResolved.push(customViewUpdatePolicyPromise)

    const loadCustomView = store.dispatch("customViews/loadCustomView", { id: +to.params.id })
    promisesArrayToBeResolved.push(loadCustomView)

    loadCustomView.then(async () => {
      const customViews = store.getters["customViews/customViews"]
      const customView  = customViews.find(customView => customView.id === +to.params.id)

      const calledFilterIds = []
      for (const chart of customView.charts) {
        const filterId = chart.savedFilterId

        if (filterId && !calledFilterIds.includes(filterId)) {
          calledFilterIds.push(filterId)

          if (loggedInUserPolicies["IssueSearch view"]) {
            const issueSearchPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
              filterId,
              excludeFilterPreferences: true,
              excludeColumnPreferences: true
            })
            promisesArrayToBeResolved.push(issueSearchPromise)

            await issueSearchPromise
            const issuesSearch = store.getters["issueSearch/issueSearch"]
            const pollPromise  = pollIssueSearch(store, issuesSearch.id, false, customView.id)
            promisesArrayToBeResolved.push(pollPromise)
          }
        }
      }
    })
  }
  await loadCustomViewsPromise
  Promise.all(promisesArrayToBeResolved)
    .then(() => {
      store.commit("shared/setProgressBarPromisesPending", false)
      store.commit("shared/setProgressBarInitiated", false)
    })
}

const pollIssueSearch = (store, id, loadIssueSearch, customViewId) => {
  return new Promise((resolve, reject) => {
    const poll = async () => {
      try {
        await store.dispatch("issueSearch/loadIssueSearch", { id })
        const issuesSearch = store.getters["issueSearch/issueSearch"]
        const status       = issuesSearch.status

        if (status === "completed") {
          let pollPromise = Promise.resolve()
          if (loadIssueSearch) {
            pollPromise = store.dispatch("issueSearch/loadIssuesFromIssueSearchId", {
              issueSearchId: issuesSearch.id,
              properties   : [],
              kpiData      : true
            })
          } else {
            store.commit("issueSearch/updateFilterIdWithIssueIds", [{ filterId: issuesSearch.filterId, issueIds: issuesSearch.result }])
            if (customViewId) {
              store.commit("customViews/setCustomViewChartFiltersToReload", { filterIds: [issuesSearch.filterId], customViewId })
            }
          }
          resolve(pollPromise)
        } else {
          setTimeout(poll, 1000)
        }
      } catch (error) {
        reject(error)
      }
    }

    poll()
  })
}